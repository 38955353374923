import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import useGetSesion from './helpers/useGetSesion';
import './App.css';


/*******************************
 *          LIBRARIES          *
 *******************************/
// CSS
import 'bootstrap/dist/css/bootstrap.css';
import "./lib/fontawesome-free-5.11.2-web/css/all.min.css"
import "./lib/font.googleapis.com.Roboto.swap.css"
// JS
import 'bootstrap/dist/js/bootstrap.js';


/********************************
 *           SUBRUTAS           *
 ********************************/
import EmpresaRoute from './Middlewares/routes/EmpresaRoute';


/***************************************
 *             MIDDLEWARES             *
 ***************************************/
import RouterConLogin from "./Middlewares/RouterConLogin"

/*******************************
 *           PAGINAS           *
 *******************************/
import ClientesListaContainer from './componentes/clientes/ClientesListaContainerN';
import Empleados from './componentes/Empleados/Empleados';
import EmpleadoAddMaster from './componentes/Empleados/EmpleadoAddMaster';
import EmpleadoEditMaster from './componentes/Empleados/EmpleadoEditMaster';
import EmpleadosMaster from './componentes/Empleados/EmpleadosMaster';
import Sucursales from './componentes/Sucursales/Sucursales';
import EditarEmpleado from './componentes/Empleados/EditarEmpleado';
import AddEmpleado from './componentes/Empleados/AddEmpleado';
import AddSucursales from './componentes/Sucursales/AddSucursal';
import EditSucursales from './componentes/Sucursales/EditSucursal';
import Almacenes from './componentes/Almacenes/Almacenes';
import AlmacenEdit from './componentes/Almacenes/AlmacenEdit';
import AlmacenAdd from './componentes/Almacenes/AlmacenAdd';
import Empresas from './componentes/Empresas';
import EmpresasEdit from './componentes/EmpresasEdit';
import EmpresasConfig from './componentes/EmpresasConfig.js';
import EmpresasConfigFacEl from './componentes/EmpresasConfigFacEl.js';
import EmpresasAdd from './componentes/AddEmpresa';
import EmpresasPrecios from './componentes/empresasComponentes/PreciosMaster.js';
import EmpresasVehiculos from './componentes/empresasComponentes/Vehiculos.js';
import EmpresasTransportistas from './componentes/empresasComponentes/Transportistas.js';
import ClientesNuevo from './componentes/clientes/ClientesNuevo';
import ClientesEditar from './componentes/clientes/ClientesEditar';
import ComboList from './componentes/clientes/ComboList';
import CPV from './componentes/PuntoVenta/ConfigPuntoVenta';
import Login from './pages/Login';
import Usuarios from './pages/Usuarios';
import Roles from './componentes/Roles';
import Landing from './Landing';
import Productos from './pages/Productos';
import NewProduct from './pages/NewProduct';
import Presentaciones from './pages/Presentaciones';
import AperturarTurnosMovcaja from './componentes/movimientosCaja/AperturarTurnosMovcaja.js';
import turnosDetalle from './componentes/turnosDetalle/turnosDetalle.js';
import turnosDetalleList from './componentes/turnosDetalle/turnosDetalleList.js';
import PreVentaAdd from './componentes/Preventas/PreVentaAdd';
import PreVentaEdit from './componentes/Preventas/PreVentaEdit';
import PreventasList from './componentes/Preventas/PreventasList'
import PreventaAlmacenes from './componentes/Preventas/PreventaAlmacenes'
import RegistrosVentas from './pages/RegistroVentas/RegistrosVentas';
import Pagos from './pages/RegistroVentas/Pagos';
import GestionPreventas from './pages/GestionPreventas';
import DetalleRegistroVenta from './pages/RegistroVentas/DetalleRegistroVenta';
import Vales from './pages/RegistroVentas/Vales';
import DetalleRegistroVale from './pages/RegistroVentas/DetalleRegistroVale';
import ProcesarVales from './pages/RegistroVentas/ProcesarVales';
import PreProcesarValesList from './pages/RegistroVentas/PreProcesarValesList.js';
import PreProcesarValesEdit from './pages/RegistroVentas/PreProcesarValesEdit.js';
import FacturacionElectronica from './componentes/FacturacionElectronica/ListaRegistrosVentas';

import Kardex from './componentes/Kardex/Kardex';
import KardexSC from './componentes/Kardex/KardexSC';

import GuiaRemision from './componentes/GuiaRemision/GuiaRemision';
import GuiaRemisionList from './componentes/GuiaRemision/GuiaRemisionList';
import GuiaRemisionEdit from './componentes/GuiaRemision/GuiaRemisionEdit';
import Vehiculos from './componentes/Vehiculos/Vehiculos'
import VehiculosAdd from './componentes/Vehiculos/VehiculosAdd'
import VehiculosAddPorEmpresa from './componentes/Vehiculos/VehiculosAddPorEmpresa'
import VehiculosEdit from './componentes/Vehiculos/VehiculosEdit'
import VehiculosEditPorEmpresa from './componentes/Vehiculos/VehiculosEditPorEmpresa'
import Transportistas from './componentes/Transportistas/Transportistas'
import TransportistasAdd from './componentes/Transportistas/TransportistasAdd'
import TransportistasAddPorEmpresa from './componentes/Transportistas/TransportistasAddPorEmpresa'
import TransportistasEdit from './componentes/Transportistas/TransportistasEdit'
import TransportistasEditPorEmpresa from './componentes/Transportistas/TransportistasEditPorEmpresa'
import Dashboard from './pages/Dashboard';
import Proveedores from './componentes/Proveedores/ProveedoresLista';
import OrdenesCompra from './componentes/Compras/OrdenCompraLista';
import DetalleProductosAgotarStock from './pages/DetalleProductosAgotarStock';
import DetallesVentasTurnosActivosEmpresa from './pages/DetalleVentasTurnosActivosEmpresa';
import ReporteVentas from './pages/RegistroVentas/ReporteVentas'
import ProductosMasVendidos from './pages/ProductosMasVendidos';
import ReportesStock from './pages/ReportesStocks';
import MovimientosAlmacen from './componentes/MovimientosAlmacen/MovimientoAlmacenLista';
import StockInicial from './componentes/Kardex/StockInicial';
import StockInicialSC from './componentes/Kardex/StockInicialSC';
import VentasPorProducto from './componentes/ReporteVentas/VentasPorProducto';
import EscogerStockInicial from './componentes/Kardex/EscogerStockInicial';
import EscogerKardex from './componentes/Kardex/EscogerKardex';

import NotasCDListController from './componentes/NotaCreditoDebito/NotasCDListController';
import LogoAplicativo from "./componentes/LogoAplicativo";
import ProcessVale from "./pages/RegistroVentas/ProcessVale";

import Creditos from "./pages/Creditos";
import Cotizaciones from "./pages/RegistroVentas/Cotizaciones";
import CrearCotizacion from "./pages/RegistroVentas/CrearCotizacion";
import DetalleCotizacion from "./componentes/Cotizaciones/DetalleCotizacion";
import ReporteDocSunatPage from "./pages/ReporteDocSunatPage";
import ReporteMovAlmacen from "./pages/ReporteMovAlmacen";
import PagoMovs from "./pages/PagoMovs";
import ReporteComprasCont from "./componentes/Contable/ReporteComprasCont";
import ReporteVentasCont from "./componentes/Contable/ReporteVentasCont";
import PreInvoiceScreen from "./componentes/invoice/PreInvoiceScreen";

import CreateNote from "./componentes/NotasCD/CreateNote";
import NotFound404 from "./componentes/NotFound404";
import UpdateCostJobScreen from "./componentes/Jobs/UpdateCostJobScreen";
import JobList from "./componentes/Jobs/JobList";
import ImportProds from "./componentes/data/ImportProds";
import PriceConsulter from "./componentes/PriceConsulter";
import {SucursalGeneralReport} from "./componentes/SucursalGeneralReport";
import {ConfirmAccess} from "./componentes/RequestAccess/ConfirmAccess";
import {RequestsAccessScreen} from "./componentes/RequestAccess/RequestsAccessScreen";
import {DisableEnableConf} from "./componentes/Jobs/DisableEnableConf";


Array.prototype.indexOfObject = function (searchElement, key) {
    let a = this;
    for (let i = 0; i < a.length; i++) {
        if (String(a[i][key]) === String(searchElement[key]))
            return i
    }
    return -1;
}


const Sesion = ({children}) => {
    useGetSesion();
    return <>{children}</>
}

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Sesion>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <RouterConLogin exact path="/" component={Landing}/>
                        <RouterConLogin exact path="/usuarios" component={Usuarios}/>
                        <RouterConLogin exact path="/roles" component={Roles}/>
                        <RouterConLogin exact path="/empleados" component={Empleados}/>
                        <RouterConLogin exact path="/empleados/master" component={EmpleadosMaster}/>
                        <RouterConLogin exact path="/empleados/edit/:IdEmpleado" component={EditarEmpleado}/>
                        <RouterConLogin exact path="/empleados/add" component={AddEmpleado}/>
                        <RouterConLogin exact path="/empleados/addMaster" component={EmpleadoAddMaster}/>
                        <RouterConLogin exact path="/empleados/editMaster/:IdEmpleado" component={EmpleadoEditMaster}/>
                        <RouterConLogin exact path="/sucursales" component={Sucursales}/>
                        <RouterConLogin exact path="/sucursales/add" component={AddSucursales}/>
                        <RouterConLogin exact path="/sucursales/edit/:IdSucursal" component={EditSucursales}/>
                        <RouterConLogin exact path="/almacenes" component={Almacenes}/>
                        <RouterConLogin exact path="/almacenes/edit/:IdAlmacen" component={AlmacenEdit}/>
                        <RouterConLogin exact path="/almacenes/add" component={AlmacenAdd}/>
                        <RouterConLogin exact path="/empresas" component={Empresas}/>
                        <RouterConLogin exact path="/empresas/edit/:id" component={EmpresasEdit}/>
                        <RouterConLogin exact path="/empresas/add" component={EmpresasAdd}/>
                        <RouterConLogin exact path="/empresas/precios" component={EmpresasPrecios}/>
                        <RouterConLogin exact path="/empresas/vehiculos" component={EmpresasVehiculos}/>
                        <RouterConLogin exact path="/empresas/Transportistas" component={EmpresasTransportistas}/>
                        <RouterConLogin exact path="/empresas/config/:id" component={EmpresasConfig}/>
                        <RouterConLogin exact path="/empresas/configFacEl/:id" component={EmpresasConfigFacEl}/>
                        <RouterConLogin exact path="/listaclientes" component={ClientesListaContainer}/>
                        <RouterConLogin exact path="/nuevocliente" component={ClientesNuevo}/>
                        <RouterConLogin exact path="/cliente/:idcliente/editar" component={ClientesEditar}/>
                        <RouterConLogin exact path="/cliente/combolist" component={ComboList}/>
                        <RouterConLogin exact path="/listapv" component={CPV}/>
                        <RouterConLogin exact path="/productos" component={Productos}/>
                        <RouterConLogin exact path="/productos/new" component={NewProduct}/>
                        <RouterConLogin exact path="/presentaciones/:IdProducto" component={Presentaciones}/>
                        <RouterConLogin exact path="/AperturarTurnosMovcaja/" component={AperturarTurnosMovcaja}/>
                        <RouterConLogin exact path="/TurnosDetalle/" component={turnosDetalle}/>
                        <RouterConLogin exact path="/TurnosDetalleList/" component={turnosDetalleList}
                                        title={"Lista deTurnos"}/>
                        <RouterConLogin exact path="/productos/edit/:IdProducto" component={NewProduct}/>
                        <RouterConLogin exact path="/preVentas/add" component={PreVentaAdd}/>
                        <RouterConLogin exact path="/preVentas/edit/:IdPreventa" component={PreVentaEdit}/>
                        <RouterConLogin exact path="/preVentas/preventasList" component={PreventasList}/>
                        <RouterConLogin exact path="/preVentas/almacenes" component={PreventaAlmacenes}/>
                        <RouterConLogin exact path="/pagos/:IdPreventa" component={Pagos}/>
                        <RouterConLogin exact path="/gestionpreventas" component={GestionPreventas}/>
                        <RouterConLogin exact path="/detalleregistroventas/:IdRegistroVenta"
                                        component={DetalleRegistroVenta}/>
                        <RouterConLogin exact path="/registrosventas" component={RegistrosVentas}/>
                        <RouterConLogin exact path="/vales" component={Vales}/>
                        <RouterConLogin exact path="/vales/get/:IdVale" component={DetalleRegistroVale}/>
                        <RouterConLogin exact path="/procesarvales" component={ProcesarVales}/>
                        <RouterConLogin exact path="/procesarvales/PreProcesarValesList"
                                        component={PreProcesarValesList}/>
                        <RouterConLogin exact path="/procesarvales/edit/:IdPreProcesarVales"
                                        component={PreProcesarValesEdit}/>
                        <RouterConLogin exact path="/facturacionelectronica" component={FacturacionElectronica}/>

                        <RouterConLogin exact path="/vales/procesar/:idsVales" component={ProcessVale}/>

                        <RouterConLogin exact path="/escogerkardex" component={EscogerKardex}/>
                        <RouterConLogin exact path="/kardex" component={Kardex}/>
                        <RouterConLogin exact path="/kardexsc" component={KardexSC}/>

                        <RouterConLogin exact path="/guiaRemision" component={GuiaRemision}/>
                        <RouterConLogin exact path="/guiaRemision/List" component={GuiaRemisionList}/>
                        <RouterConLogin exact path="/guiaRemision/Edit/:IdGuiaRemision" component={GuiaRemisionEdit}/>
                        <RouterConLogin exact path="/vehiculos" component={Vehiculos}/>
                        <RouterConLogin exact path="/vehiculos/add" component={VehiculosAdd}/>
                        <RouterConLogin exact path="/vehiculos/addPorEmpresa/:IdEmpresa"
                                        component={VehiculosAddPorEmpresa}/>
                        <RouterConLogin exact path="/vehiculos/edit/:IdVehiculo" component={VehiculosEdit}/>
                        <RouterConLogin exact path="/vehiculos/editPorEmpresa/:IdVehiculo"
                                        component={VehiculosEditPorEmpresa}/>
                        <RouterConLogin exact path="/transportistas" component={Transportistas}/>
                        <RouterConLogin exact path="/transportistas/add" component={TransportistasAdd}/>
                        <RouterConLogin exact path="/transportistas/addPorEmpresa/:IdEmpresa"
                                        component={TransportistasAddPorEmpresa}/>
                        <RouterConLogin exact path="/transportistas/edit/:IdTransportista"
                                        component={TransportistasEdit}/>
                        <RouterConLogin exact path="/transportistas/editPorEmpresa/:IdTransportista"
                                        component={TransportistasEditPorEmpresa}/>

                        <RouterConLogin exact path="/dashboard" component={Dashboard}/>
                        <RouterConLogin exact path="/proveedores" component={Proveedores}/>
                        <RouterConLogin exact path="/ordenescompra" component={OrdenesCompra}/>
                        <RouterConLogin exact path="/dashboard/productosporagotarstock"
                                        component={DetalleProductosAgotarStock}/>
                        <RouterConLogin exact path="/dashboard/ventas/turnosactivos/empresa"
                                        component={DetallesVentasTurnosActivosEmpresa}/>
                        <RouterConLogin exact path="/registrosventas/reportes" component={ReporteVentas}/>
                        <RouterConLogin exact path="/dashborad/productosmasvendidos" component={ProductosMasVendidos}/>
                        <RouterConLogin exact path="/movimientosalmacen" component={MovimientosAlmacen}/>

                        <RouterConLogin exact path="/escogerstockinicial" component={EscogerStockInicial}/>
                        <RouterConLogin exact path="/stockinicial" component={StockInicial}/>
                        <RouterConLogin exact path="/stockinicialSC" component={StockInicialSC}/>

                        <RouterConLogin exact path="/dashborad/reportesstock" component={ReportesStock}/>

                        <RouterConLogin exact path="/VentasPorProducto" component={VentasPorProducto}/>

                        <RouterConLogin exact path="/ListaNotasCD" component={NotasCDListController}/>

                        <RouterConLogin path="/empresa" component={EmpresaRoute}/>

                        <RouterConLogin path="/LogoAplicativo" component={LogoAplicativo}/>

                        <RouterConLogin path="/creditos" component={Creditos}/>

                        <RouterConLogin path="/cotizaciones/crear" component={CrearCotizacion}/>

                        <RouterConLogin path="/cotizaciones/detalle/:id" component={DetalleCotizacion}/>

                        <RouterConLogin path="/cotizaciones" component={Cotizaciones}/>

                        <RouterConLogin path="/reporte-docs-sunat" component={ReporteDocSunatPage}/>

                        <RouterConLogin path="/reporte-movs" component={ReporteMovAlmacen}/>

                        <RouterConLogin path="/pagos-movs" component={PagoMovs}/>

                        <RouterConLogin path="/reporte-compras" component={ReporteComprasCont}/>

                        <RouterConLogin path="/reporte-ventas" component={ReporteVentasCont}/>

                        <RouterConLogin path="/pre-invoice/add/:id?" component={PreInvoiceScreen}/>

                        <RouterConLogin path="/jobs/add/update-cost" component={UpdateCostJobScreen}/>

                        <RouterConLogin path="/jobs/add/disable-enable-conf" component={DisableEnableConf}/>

                        <RouterConLogin path="/jobs" component={JobList}/>

                        <RouterConLogin path="/note/:noteId?/:readOnly?" component={CreateNote}/>

                        <RouterConLogin path="/import/prods" component={ImportProds}/>

                        <RouterConLogin path="/registrosventas/reportes/sucursales" component={SucursalGeneralReport}
                                        additional={{
                                            labelAccepted: "Total aceptados S./",
                                            reportUrl: "/api/ventas/general-sucursal",
                                            title: "Reporte de totales por sucursal - Ventas"
                                        }}
                        />

                        <RouterConLogin path="/price-consulter" component={PriceConsulter}/>

                        <RouterConLogin path="/check-access" component={ConfirmAccess}/>

                        <RouterConLogin path="/pre-invoice/edit/:id" component={PreInvoiceScreen}/>

                        <RouterConLogin path="/requests-access" component={RequestsAccessScreen}/>

                        <RouterConLogin path="/vales/reportes/sucursales"
                                        component={SucursalGeneralReport}
                                        additional={{
                                            labelAccepted: "Total procesados S./",
                                            reportUrl: "/api/vales-reports/general-sucursal",
                                            title: "Reporte de totales por sucursal - Vales"
                                        }}/>

                        <RouterConLogin path="/not-found" component={NotFound404}/>
                    </Switch>
                </Sesion>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
