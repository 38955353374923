import React from "react";
import DocTypeSelector from "./DocTypeSelector";
import ClientPicker from "./ClientPicker";
import InvoiceInfoInput from "./InvoiceInfoInput";
import ProductPicker from "./ProductPicker";
import DetallesProductoPreventa from "../Preventas/DetallesProductoPreventa";
import Modal from "../Modal";
import PreventaModalDescuentos from "../Preventas/PreventaModalDescuentos";
import {BOLETA, calcTotal, FACTURA, NUMBER_KEYS, PRECIO_COSTO} from "../../Global";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto,
    getPrecioPorMayor
} from "../Preventas/PreciosPreventa";
import $ from "jquery";
import _ from "lodash";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";


export default class PreInvoiceSubForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {detalles: []},
            afectsIgv: [],
            isLoadingPreventa: false,
            isLoadingDetails: false,
        }
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this)
        this.onSelectItem = this.onSelectItem.bind(this)
        this.onDeleteItem = this.onDeleteItem.bind(this)
        this.onChangeCantidad = this.onChangeCantidad.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onSubstractQuantity = this.onSubstractQuantity.bind(this)
        this.onAddQuantity = this.onAddQuantity.bind(this)
        this.onShowModalDescuento = this.onShowModalDescuento.bind(this)
        this.onChangeDiscount = this.onChangeDiscount.bind(this)
        this.onChangeAfectFree = this.onChangeAfectFree.bind(this)
        this.onChangeTotal = this.onChangeTotal.bind(this)
        this.onSelectPrice = this.onSelectPrice.bind(this)
    }

    componentDidMount() {
        if (this.props.preventaId) {
            this.fetchPreventa(this.props.preventaId)
            this.fetchDetallesPreventa(this.props.preventaId)
        }
    }

    fetchPreventa(id) {
        pfetch({
            fetch: {
                url: "/api/gestionPreventas/preventa/" + id,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoadingPreventa: true}),
            then: (d) => {
                const preInvoice = d.respuesta[0]
                this.setState((state) => {
                    return {
                        payload: {
                            ...state.payload,
                            ...preInvoice,
                        }
                    }
                }, this.onChange)
            },
            finally: () => this.setState({isLoadingPreventa: false})
        })
    }

    fetchDetallesPreventa(id) {
        pfetch({
            fetch: {
                url: `/api/gestionPreventas/detallepreventa/${id}`,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoadingDetails: true}),
            then: (data) => {
                this.updateState(data.respuesta.map(d => {
                    return {
                        ...d,
                        oldPrecios: [],
                        oldCants: [],
                        PrecioCosto: d.precios.find(p => p.IdPrecioPlantilla === PRECIO_COSTO).Precio
                    }
                }))
            },
            finally: () => this.setState({isLoadingDetails: false})
        })
    }

    onSecretKeyPress(e, item) {
        if (e.ctrlKey && e.shiftKey && e.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this._onChangePrice(item, item.precioMayor.Precio, "Mayor")
    }

    onSelectPrice(e, item) {
        this._onChangePrice(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    onChangeCantidad(e, item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value;
        items[i].Cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(val) : val;
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.updateState(items)
    }

    onPriceChange(e, item) {
        const items = [...this.state.payload.detalles]
        const val = e.target.value;
        const i = items.indexOfObject(item, 'IdPresentacion');
        items[i].oldPrecios.push(items[i].PrecioVenta);
        items[i].PrecioVenta = val
        items[i].Total = calcTotal(items[i]) - items[i].Descuento
        this.updateState(items)
    }

    onAddQuantity(item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        items[i].Cantidad++
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i])
        this.updateState(items)
    }

    onSubstractQuantity(item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        items[i].Cantidad--
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i])
        this.updateState(items)
    }

    onDeleteItem(item) {
        let items = [...this.state.payload.detalles];
        items.splice(items.indexOfObject(item, 'IdPresentacion'), 1)
        this.updateState(items)
    }

    onChangeDiscount(e, item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value
        if (Number(items[i].Gratuito) === 0) {
            items[i].Descuento = val
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            this.updateState(items, {selectedItem: items[i]})
        }
    }

    onChangeTotal(e, item) {
        const items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        const val = e.target.value;

        items[i].Cantidad = val / items[i].PrecioVenta
        items[i].Total = val
        items[i].Descuento = 0
        this.updateState(items)
    }

    _onChangePrice(item, val, textPrice) {
        let items = [...this.state.payload.detalles]
        const i = items.indexOfObject(item, 'IdPresentacion')
        let foundedItem = items[i];
        const precio = foundedItem.precios.find(p => p.NombrePrecio.includes(textPrice));

        foundedItem.Precio = precio ? precio.Precio : 0
        foundedItem.PrecioVenta = precio ? precio.Precio : 0
        foundedItem.ValorUnitario = precio ? precio.ValorUnitario : 0;
        foundedItem.Total = calcTotal({Cantidad: items[i].Cantidad, PrecioVenta: foundedItem.PrecioVenta})
        foundedItem.PrecioReferencial = parseFloat(foundedItem.PrecioVenta)

        this.updateState(items)
    }

    onSelectItem(item) {
        $("#InputBuscarProd").focus();
        let items = [...this.state.payload.detalles];
        const i = items.indexOfObject(item, 'IdPresentacion')

        if (i !== -1) {
            items[i].Cantidad++;
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else {
            FindPrecioPorMayor([], item)
            items.push({
                ...item,
                Cantidad: 1,
                PrecioVenta: item.PrecioVenta,
                Total: item.PrecioVenta,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                checked: _.isNumber(item.checked) ? item.checked : 0,
                oldPrecios: [item.PrecioVenta],
                initialAfectGrat: item.IdAfectacionIgv,
                oldCants: [1]
            })
        }
        this.updateState(items)
    }

    updateState(items, additional = {}) {
        this.setState((state, props) => {
            return {
                payload: {
                    ...state.payload,
                    detalles: items
                },
                ...additional
            }
        }, this.onChange)
    }

    onShowModalDescuento(item) {
        this.setState({showDiscountModal: true, selectedItem: item})
        this.fetchAfects(item.IdGrupoClasificacionIgv)
    }

    fetchAfects(idAfect) {
        pfetch({
            fetch: {
                url: `/api/preVentas/free-afects?idGrupoIgv=${idAfect}`,
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.setState({afectsIgv: d}),
            catch: () => notificarError("Ha ocurrido un error al momento de extraer las afectaciones gratuitas"),
            finally: () => this.setState({isLoading: false})
        })
    }

    onChangeAfectFree(e, item) {
        let items = [...this.state.payload.detalles];
        let i = items.indexOfObject(item, 'IdPresentacion');
        const val = e.target.value

        const checked = String(val) !== "-1";
        items[i].checked = checked
        items[i].Gratuito = checked ? 1 : 0

        if (checked) {
            items[i].Descuento = 0
            items[i].IdAfectacionIgv = val
        } else
            items[i].IdAfectacionIgv = items[i].initialAfectGrat

        this.updateState(items)
    }

    onChange() {
        this.props.onChange({...this.state.payload})
    }

    render() {
        const {conf} = this.props
        const {payload, afectsIgv} = this.state

        return (
            <>
                <div className="encabezado">

                    <DocTypeSelector
                        hideDocKeys={this.props.hideDocKeys}
                        value={payload.IdTipoDocumentoSunat ? payload.IdTipoDocumentoSunat : BOLETA}
                                     onChange={d => {
                                         this.setState((state, props) => {
                                             return {
                                                 payload: {
                                                     ...state.payload,
                                                     _docType: d,
                                                     IdTipoDocumentoSunat: d ? d.key : d,
                                                 }
                                             }
                                         }, this.onChange)
                                     }}/>

                    <div className="preventa__cliente mt-1">

                        <ClientPicker defaultClient={payload.NroTipoDocumento} onSelectClient={c => {
                            this.setState((state, props) => {
                                return {
                                    payload: {
                                        ...state.payload,
                                        IdTipoDocumentoSunat: c.NroTipoDocumento.length === 11 ? FACTURA : state.payload.IdTipoDocumentoSunat,
                                        _client: c
                                    }
                                }
                            }, this.onChange)
                        }}/>

                        <InvoiceInfoInput defaultText={payload.Alias} onChangeInfo={e => {
                            this.setState((state, props) => {
                                return {
                                    payload: {
                                        ...state.payload,
                                        alias: e
                                    }
                                }
                            }, this.onChange)
                        }} placeholder={conf.useAliasAsOther ? "Placa (opcional)" : "Alias"}/>
                    </div>

                    <ProductPicker
                        showSwitchStock={this.props.showSwitchStock}
                        showDefaultColor={this.props.showDefaultColor}
                        stockKey={this.props.stockKey}
                        prodsUrl={this.props.prodsUrl} onSelectItem={this.onSelectItem}
                        cleanSearch={conf.cleanableSearch}/>

                </div>

                <div className="preventa__container_data">

                    <div className="preventa__cliente2">
                        {payload._client ? `${payload._client.RazonSocial}` : "CLIENTE NO REGISTRADO"}
                    </div>

                    <DetallesProductoPreventa
                        defaultBgInProductDetail={this.props.defaultBgInProductDetail}
                        load={this.state.isLoading}
                        detalles={payload.detalles}
                        onSecretKeyPress={this.onSecretKeyPress}
                        venderConListaPrecios={Number(conf.canSeePrices)}
                        PrecioChange={this.onSelectPrice}
                        InputCantidadDetalleChange={this.onChangeCantidad}
                        InputPrecioVentaChange={this.onPriceChange}
                        RestarCantidadDet={this.onSubstractQuantity}
                        AgregarCantidadDet={this.onAddQuantity}
                        RemoveProductoInList={this.onDeleteItem}
                        handleOpenModalDescuento={this.onShowModalDescuento}
                        onBlurInputCantidad={() => {
                        }}
                        onBlurPrecioVenta={() => {
                        }}
                        canUpdatePrice={conf.canUpdatePrice}
                        canUpdateTotal={conf.canUpdateTotal}
                        onChangeTotal={this.onChangeTotal}
                        onBlurTotal={() => {
                        }}
                    />

                </div>

                <Modal title="Realizar descuento" isOpen={this.state.showDiscountModal}
                       onClose={() => this.setState({showDiscountModal: false})}>
                    {this.state.isLoading ?
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"/>
                        </div> :
                        <PreventaModalDescuentos
                            load={this.state.isLoading}
                            ItemDescuento={this.state.selectedItem}
                            DescuentoChange={this.onChangeDiscount}
                            onBlurDescuento={() => {
                            }}
                            afectsIgv={afectsIgv}
                            onChangeCheckBoxGratuito={this.onChangeAfectFree}
                        />
                    }
                </Modal>
            </>
        );
    }
}
