import React from "react";
import "./styles/DetallePago.css";
import {
    EFECTIVO,
    MASTER,
    VISA,
    AMERICAN,
    OTROS,
    PAGO_CREDITO, decimalAdjust
} from "../../Global";
import EfectivoImg from "./images/efectivo.png";
import Mastercard from "./images/mastercard.png";
import Visa from "./images/visa.png";
import American from "./images/american.png";
import OtrosPagos from "./images/otros.png";
import Credito from "./images/credito.png";
import moment from "moment";

const DetallePagoUI = props => {

    return (

        <>
            <div>
                <div className="detalle__pago">
                    <div className="items__detalle__pago">
                        {props.IdTipoPago === EFECTIVO && (
                            <input
                                type="image"
                                src={EfectivoImg}
                                className="btn btn-outline-success item__detalle__pago--img"
                            />
                        )}
                        {props.IdTipoPago === MASTER && (
                            <input
                                type="image"
                                src={Mastercard}
                                className="btn btn-outline-danger item__detalle__pago--img"
                            />
                        )}
                        {props.IdTipoPago === VISA && (
                            <input
                                type="image"
                                src={Visa}
                                className="btn btn-outline-primary item__detalle__pago--img"
                            />
                        )}
                        {props.IdTipoPago === AMERICAN && (
                            <input
                                type="image"
                                src={American}
                                className="btn btn-outline-info item__detalle__pago--img"
                            />
                        )}
                        {props.IdTipoPago === OTROS && (
                            <input
                                type="image"
                                src={OtrosPagos}
                                className="btn btn-outline-warning item__detalle__pago--img"
                            />
                        )}
                        {props.IdTipoPago === PAGO_CREDITO && (
                            <div>
                                <input
                                    type="image"
                                    src={Credito}
                                    className="btn btn-outline-danger item__detalle__pago--img"
                                />
                            </div>
                        )}
                        <input
                            className="item__detalle__pago"
                            type="number"
                            autoFocus
                            onFocus={props.handleFocus}
                            placeholder="Monto"
                            value={decimalAdjust('round', props.Monto, -2)}
                            onChange={props.handleMontoPago}
                            tabIndex="2"
                            onKeyDown={props.handlePagoEnterKeyUp}
                        />

                        {props.IdTipoPago !== PAGO_CREDITO ? (
                            <input
                                type="text"
                                className="item__detalle__pago"
                                placeholder="Ref."
                                value={props.Referencia}
                                onChange={props.handleReferenciaPago}
                            />
                        ) : (
                            <div>
                                <input
                                    type="date"
                                    className="item__detalle__pago"
                                    value={moment(props.fechaPago).format("YYYY-MM-DD")}
                                    onChange={props.handleSelectPlazosCredito}
                                />
                            </div>
                        )}
                        <button
                            type="button"
                            className="boton__outline-pequeno--r"
                            onClick={props.handleRemovePago}
                        >
                            {<i className="fas fa-trash-alt"/>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetallePagoUI;
