import React from "react";
import ReactTable from "react-table";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import moment from "moment";
import {convertCronToDate} from "../../helpers/utils";

export default class JobList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            table: {
                pageSize: 50,
                sorted: false,
                filtered: false,
                isLoading: true,
                pages: -1,
            }
        }
        this.fetchJobs = this.fetchJobs.bind(this)
        this.onDeletingJob = this.onDeletingJob.bind(this)
    }

    setTableState(newTableState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newTableState,
                }
            }
        })
    }

    fetchJobs(state, instance) {
        this.setTableState({isLoading: true})
        fetch("/api/jobs/filter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            })
        })
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d
                if (status === 200) {
                    this.setState({
                        jobs: data.jobs.map(j => {
                            return {
                                ...j,
                                execInfo: JSON.parse(j.execInfo),
                            }
                        })
                    })
                    this.setTableState({pages: data.pages})
                }
            })
            .catch(() => notificarError("Ha ocurrido un error al momento de listar los jobs"))
            .finally(() => this.setTableState({isLoading: false}))
    }

    onDeletingJob(e) {
        const id = e.target.getAttribute('data-job-id')
        this.setTableState({isLoading: true})
        fetch(`/api/jobs/delete/${id}`, {
            method: "DELETE",
        })
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d
                if (status === 200) {
                    notificarMsg("Se ha eliminado con éxito")
                    this.fetchJobs(this.state.table, null)
                } else
                    notificarError("No se pudo eliminar el job")
            })
            .catch(() => notificarError("No se pudo eliminar el job"))
    }


    render() {
        const {jobs, table} = this.state
        return (
            <div className="mx-3 my-3">
                <div className="row">
                    <div className="col-sm-auto">
                        <h3>Lista de trabajos programados</h3>
                    </div>
                    <div className="col-sm-auto">
                        <button onClick={() => this.props.history.push("/jobs/add/update-cost")}
                                className="btn btn-link">
                            Crear Job para actualizar costos
                        </button>
                    </div>
                    <div className="col-sm-auto">
                        <button onClick={() => this.props.history.push("/jobs/add/disable-enable-conf")}
                                className="btn btn-link">
                            Crear Job para activar/desactivar configuración
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <ReactTable
                            data={jobs}
                            pages={table.pages}
                            loading={table.loading}
                            manual={true}
                            filterable={true}
                            sortable={true}
                            onFetchData={(state, instance) => this.fetchJobs(state, instance)}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "ID",
                                            id: "id",
                                            accessor: d => d.id,
                                            width: 90,
                                            Cell: f => {
                                                return <div>
                                                    {f.original.id}
                                                    {f.original.isActive ?
                                                        <button onClick={this.onDeletingJob} data-job-id={f.original.id}
                                                                className="btn btn-outline-danger ml-2">Eliminar</button> : ""}
                                                </div>
                                            }
                                        },
                                        {
                                            Header: "Nombre",
                                            id: "name",
                                            width: 90,
                                            Cell: f => f.original.name,
                                        },
                                        {
                                            Header: "Programado",
                                            id: "schedule",
                                            width: 180,
                                            Cell: f => moment(convertCronToDate(f.original.schedule)).format("DD/MM/YYYY HH:mm")
                                        },
                                        {
                                            Header: "Cantidad de ejecuciones",
                                            id: "execCount",
                                            width: 150,
                                            accessor: d => d.execCount
                                        },
                                        {
                                            Header: "Estado de ejecución",
                                            id: "state",
                                            width: 150,
                                            Cell: f => f.original.state
                                        },
                                        {
                                            Header: "Estado en cola (1 o 0)",
                                            id: "isActive",
                                            width: 150,
                                            Cell: f => f.original.isActive ? "En cola" : "Fuera de cola"
                                        },
                                        {
                                            Header: "Resultado de ejecución",
                                            id: "executionResult",
                                            width: 150,
                                            accessor: d => d.executionResult || "",
                                        },
                                        {
                                            Header: "Fecha de creado",
                                            id: "createdAt",
                                            width: 150,
                                            Cell: f => moment(f.original.createdAt).format("DD/MM/YYYY HH:mm")
                                        },
                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            previousText="Anterior"
                            nextText="Siguiente"
                            noDataText="No se encontraron registros"
                            loadingText="Cargando..."
                            ofText="de"
                            rowsText="filas"
                            pageText="Página"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                    </div>
                </div>

            </div>
        );
    }
}
